<template>
  <div>
    <Tb />

    <div class="pt-3 ml-2 mb-2">
      <b-row class="">
        <b-col lg="6" class="pt-3 mt-2">
          <scrollactive>
            <iframe
              id="mapIframe"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d349.34019788862435!2d73.04763316155982!3d33.59225663443024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df95e44695baa1%3A0x167ab805d03e9045!2sVeterans%20Facilitation%20Center!5e0!3m2!1sen!2sus!4v1695365111312!5m2!1sen!2sus"
              title="Facilitation center"
              style="width: 100%; border: 2px solid #46755a !important"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            >
            </iframe>
          </scrollactive>
        </b-col>
        <b-col md="6" class="pl-3">
          <h1 class="pt-5 heading-text">{{ $t("contactUs.Touch") }}</h1>
          <h3 class="pr-4 heading-text2">
            {{ $t("contactUs.Assistance") }}
          </h3>

          <h3 class="pr-4 heading-text2">
            {{ $t("contactUs.Fill") }}
          </h3>
          <validation-observer ref="contactUsCreateFormValidation">
            <b-row>
              <b-col md="6">
                <b-form-group label-for="name">
                  <!-- <label>Name</label> -->
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name"
                      class="text-black"
                      style="border: 2px solid #46755a"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group label-for="mobile">
                  <!-- <label>Mobile Number</label> -->
                  <validation-provider
                    #default="{ errors }"
                    name="mobile"
                    rules="required|integer|positive|length:11"
                  >
                    <b-form-input
                      id="mobile"
                      v-model="mobile"
                      disabled
                      :state="errors.length > 0 ? false : null"
                      style="border: 2px solid #46755a"
                      placeholder="Mobile Number"
                      class="text-black"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group label-for="email">
                  <!-- <label>E-mail</label> -->
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    :rules="{ required, email: true }"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="E-mail"
                      style="border: 2px solid #46755a"
                      maxlength="65"
                      class="text-black"
                      v-b-tooltip.hover
                      title="Enter your email adress e.g abc@xyz.com"
                      type="email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group label-for="message">
                  <!-- <label>message</label> -->
                  <validation-provider
                    #default="{ errors }"
                    name="message"
                    :rules="{ required }"
                  >
                    <b-textarea
                      id="message"
                      v-model="message"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Message"
                      style="border: 2px solid #46755a"
                      maxlength="500"
                      class="text-black"
                      v-b-tooltip.hover
                      title="Enter your message."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="">
                <h3 class="heading-text2">
                  {{ $t("contactUs.Captcha") }}
                </h3>
                <b-row>
                  <b-col md="6.8" class="px-1">
                    <VueRecaptcha
                      id="captcha"
                      :sitekey="recaptchaSiteKey"
                      :load-recaptcha-script="true"
                      @verify="handleSuccess"
                      @error="handleError"
                    ></VueRecaptcha>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col md="11" class="mt-1">
                    <validation-provider
                      name="privacyPolicy"
                      :rules="{ required: true }"
                    >
                      <b-checkbox
                        v-model="privacyPolicyChecked"
                        id="privacyPolicy"
                        name="privacyPolicy"
                      >
                      </b-checkbox>
                      <h4 class="heading-text3">
                        By submitting this form, you agree to our
                        <span class="heading-text2"> Privacy Policy</span>
                      </h4>
                      <small v-if="!privacyPolicyChecked" class="text-danger"
                        >Checkbox field is required</small
                      >
                    </validation-provider>
                  </b-col>
                </b-row> -->

                <b-row>
                  <b-col md="6" class="pt-1">
                    <b-button
                      type="submit"
                      variant="primary"
                      block
                      @click="sendMessage"
                    >
                      Submit
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { $themeConfig } from "@themeConfig";
import Contact from "@/components/newHome/Contact.vue";
import Footer from "@/components/newHome/Footer.vue";
import Navbar from "@/components/newHome/Nav.vue";
import Tb from "@/components/newHome/Tb.vue";
import { VueRecaptcha } from "vue-recaptcha";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    Contact,
    Footer,
    Navbar,
    Tb,
    VueRecaptcha,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      email: "",
      message: "",
      name: "",
      mobile: "",
      recaptchaSiteKey: "6LdzLXUoAAAAAAsV5gyUxFQTm2HxK8EWymL6mBza",
      captcha: false,
    };
  },

  methods: {
    ...mapActions({
      createContactUs: "appData/createContactUs",
    }),

    // async sendMessage() {
    //   try {
    //     if (this.captcha === false) {
    //       await this.$refs.contactUsCreateFormValidation.validate();
    //       this.$swal({
    //         title: "reCAPTCHA verification failed",
    //         icon: "warning",
    //         timmer: 3000,
    //       });
    //       return;
    //     } else {
    //       const formValidationSuccess =
    //         await this.$refs.contactUsCreateFormValidation.validate();

    //       if (formValidationSuccess) {
    //         let data = {
    //           email: this.email,
    //           message: this.message,
    //           name: this.name,
    //           mobile: this.mobile,
    //         };

    //         let res = await this.createContactUs(data);

    //         if (res.status === 201) {
    //           this.$swal({
    //             title:
    //               "Your message sent successfully, soon our operator will contact you",
    //             icon: "success",
    //           });
    //           this.$refs.contactUsCreateFormValidation.reset();
    //         }
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error during message creation:", error);
    //     this.$refs.contactUsCreateFormValidation.reset();
    //   }
    // },

    async sendMessage() {
      try {
        const formValidationSuccess =
          await this.$refs.contactUsCreateFormValidation.validate();
        if (formValidationSuccess) {
          if (!this.captcha) {
            this.$swal({
              title: "reCAPTCHA verification failed",
              icon: "warning",
              timmer: 3000,
            });
            return;
          } else {
            this.submit();
          }
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    async submit() {
      try {
        let data = {
          email: this.email,
          message: this.message,
          name: this.name,
          mobile: this.mobile,
        };

        let res = await this.createContactUs(data);

        if (res.status === 201) {
          this.$swal({
            title:
              "Your message sent successfully, soon our operator will contact you",
            icon: "success",
          });
          this.$refs.contactUsCreateFormValidation.reset();
          this.email = "";
          this.message = "";
        }
      } catch (error) {
        this.displayError(error);
        this.$refs.contactUsCreateFormValidation.reset();
      }
    },

    handleSuccess() {
      this.captcha = true;
    },
    handleError(error) {
      error;
    },
    reset() {
      // this.selectedMarkTo = null;
      this.email = "";
      this.message = "";
      this.name = "";
      this.mobile = "";
      this.$refs.contactUsCreateFormValidation.reset();
    },
  },

  computed: {
    ...mapGetters({
      getUser: "appData/getUser",
      getLoggedInUser: "appData/getUser",
    }),

    // getColStyle() {
    //   const windowHeight = window.innerHeight;

    //   const height = `${windowHeight - 450}px`;

    //   return {
    //     height,
    //   };
    // },
  },

  async mounted() {
    const setIframeHeight = () => {
      const iframe = document.getElementById("mapIframe");
      const windowHeight = window.innerHeight;
      iframe.style.height = `${windowHeight - 450}px`;
    };
    const userData = await this.getUser;
    this.name = userData.name;
    this.mobile = userData.mobile;
    setIframeHeight();
    window.addEventListener("resize", setIframeHeight);
  },
};
</script>
<style>
.text-black {
  color: black !important;
  /* font-size: 1.2rem; */
}
</style>

<style scoped>
.heading-text {
  font-weight: bolder;
  font-size: 2.5rem;
  text-align: start;
  color: #46755a;
}
.heading-text2 {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: start;
  color: #46755a;
}
.heading-text3 {
  font-weight: bold;
  font-size: 0.9rem;
  text-align: start;
  color: #46755a;
}

#captcha {
  border: 2px solid #46755a !important;
}
label {
  font-size: 110%;
  font-weight: 400;
  color: #000;
}
::placeholder {
  color: black;
}
</style>
